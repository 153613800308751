<footer class="pt-lg-8 pt-5 footer bg-white">
  <div class="container mt-lg-2">
    <!--<div class="row">
      <div class="col-lg-4 col-md-6 col-12">
        <h3 class="fw-bold mb-3 text-center">Adison Jimenez</h3>
        <div class="mb-4">
          <div class="mt-4">
            <p class="text-center">
              Dedicado a la creación y mejora constante de programas y aplicaciones informáticas,
              implicado en diseñar soluciones eficientes para resolver problemas específicos,
              escribir y depurar código, así como colaborar estrechamente con equipos multidisciplinarios.
            </p>
            <div class="fs-4 mt-4 text-center text-info">
              <a href="#" class="me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
              </a>
              <a href="#" class="me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </a>
              <a href="#" class="me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
                  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="offset-lg-1 col-lg-2 col-md-3 col-6">
        <div class="mb-4 text-center">
          <h3 class="fw-bold mb-3">
            <span>Acerca De</span>
          </h3>
          <ul class="list-unstyled nav nav-footer flex-column nav-x-0">
            <li>
              <a href="work/external/study" class="nav-link">
                <span>Estudios</span>
              </a>
            </li>
            <li>
              <a href="work/external/portfolio" class="nav-link">
                <span>Portafolio</span>
              </a>
            </li>
            <li>
              <a href="work/external/contact" class="nav-link">
                <span>Contacto</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-6">
        <div class="mb-4 text-center">
          <h3 class="fw-bold mb-3">
            <span>Contribuciones</span>
          </h3>
          <ul class="list-unstyled nav nav-footer flex-column nav-x-0">
            <li>
              <a class="nav-link" target="_blank" rel="noopener"
              href="https://www.paypal.com/donate/?hosted_button_id=ACV7LKW4SWRNJ">
                <span>Donacion</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="mb-4 text-center">
          <h3 class="fw-bold mb-3">
            <span>Contacto</span>
          </h3>
          <p>Colombia, Bogota D.C.</p>
          <p class="mb-3">
            Email:
            <a href="#" class="text-info">
              <span>contacto adisonjimenez.work</span>
            </a>
          </p>
          <p class="d-none mb-3">
            Telefono:
            <span class="text-dark fw-semibold">(+57) 300 300 3030</span>
          </p>
          <div class="d-flex d-none">
            <a href="#"><img src="{{ urlFront }}/assets/geeks/images/svg/appstore.svg" alt="" class="img-fluid" /></a>
            <a href="#" class="ms-2"><img src="{{ urlFront }}/assets/geeks/images/svg/playstore.svg" alt="" class="img-fluid" /></a>
          </div>
        </div>
      </div>
    </div>-->
    <div class="row align-items-center g-0 border-top py-2 mt-6">
      <!-- Desc -->
      <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 justify-content-center">
        <div class="d-lg-flex align-items-center">
          <div class="text-center d-flex align-items-center">
            <nav class="nav nav-footer">
              <a class="nav-link ps-0 text-info" href="work/external/landing">
                <img src="{{ urlFront }}/assets/apps/images/logo/logo-blue.svg"
                alt="{{ appTitle }}" class="img-fluid me-1" width="75" height="10" />
                <span>Copyright © 2014 - 2024. All rights reserved.</span>
              </a>
            </nav>
          </div>
        </div>
      </div>

      <!-- Links -->
      <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-md-flex justify-content-end">
        <!--<nav aria-label="" class="nav nav-footer">
          <a class="nav-link ps-0" href="work/external/study">
            <span class="text-info">Estudios</span>
          </a>
          <a class="nav-link ps-0" href="work/external/porfolio">
            <span class="text-info">Portafolio</span>
          </a>
          <a class="nav-link ps-0" href="work/external/contact">
            <span class="text-info">Contacto</span>
          </a>
        </nav>-->
      </div>
    </div>
  </div>
</footer>

<!-- Scroll top -->
<div class="btn-scroll-top">
  <svg class="progress-square svg-content" width="100%" height="100%" viewBox="0 0 40 40">
    <path d="M8 1H32C35.866 1 39 4.13401 39 8V32C39 35.866 35.866 39 32 39H8C4.13401 39 1 35.866 1 32V8C1 4.13401 4.13401 1 8 1Z" />
  </svg>
</div>
