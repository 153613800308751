import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = this.extract();
  }

  private extract(): string {
    const baseTag = document.querySelector('base');
    return baseTag?.getAttribute('href') ?? '/';
  }

  getCurrentUrl(): string {
    const { protocol, host } = window.location;
    //console.log(`${protocol}//${host}`);
    return `${protocol}//${host}`;
  }

  getBaseUrl(): string {
    //console.log(`baseUrl: ${this.baseUrl}`);
    return this.baseUrl;
  }

  // Construir URL completa basada en parámetros
  buildUrl(
    protocol: string,
    port: string,
    sub: string,
    domain: string,
    ext: string
  ): string {
    let baseUrl = `${protocol}://`;
    if (sub) { baseUrl += `${sub}.`; }
    baseUrl += `${domain}.${ext}`;
    if (port) { baseUrl += `:${port}`; }
    return baseUrl;
  }

  buildPtcl(
    port: string,
    sub: string,
    domain: string,
    extension: string
  ): string {
    let baseUrl = '';
    if (sub) { baseUrl += `${sub}.`; }
    baseUrl += `${domain}.${extension}`;
    if (port) { baseUrl += `:${port}`; }
    return baseUrl;
  }

  getDomainPtcl(): string {
    let extension = this.getDomainExtension();
    let port = '', sub = '', domain = 'adisonjimenez';
    let url = this.buildPtcl(port, sub, domain, extension);
    //console.log(`Url Domain Ptcl: ${url}`);
    return url;
  }

  getDomainSub(
    protocol: string,
    port: string,
    sub: string,
    domain: string
  ): string {
    let extension = this.getDomainExtension();
    let url = this.buildUrl(
      protocol,
      port,
      sub,
      domain,
      extension
    );
    //console.log(`Url Domain Major: ${url}`);
    return url;
  }

  getDomainMajor(): string {
    let extension = this.getDomainExtension();
    let protocol = 'https', port = '', sub = '', domain = 'adisonjimenez';
    let url = this.buildUrl(protocol, port, sub, domain, extension);
    //console.log(`Url Domain Major: ${url}`);
    return url;
  }

  getDomainExtension(): string {
    let current = this.getCurrentUrl();
    // Obtener la extensión de la URL actual
    let parts = current.split('.');
    let extension = parts.length > 1 ? parts.pop() : '';
    // Retornar extension capturada
    return extension || 'net';
  }
}
