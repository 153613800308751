import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/funcs/base.service';

@Component({
  selector: 'app-ge-in-navbar',
  templateUrl: './ge-in-navbar.component.html',
  styleUrls: ['./ge-in-navbar.component.css']
})
export class GeInNavbarComponent implements OnInit {
  appTitle: string = Environment.title;
  domainMajor: string = '';
  domainPtcl: string = '';
  urlFront: string = '';

  domainIntg: string = '';
  domainOdoo: string = '';
  domainPex: string = '';
  domainPiis: string = '';
  domainPsx: string = '';constructor (
    private router: Router,
    private serviceBase: BaseService,
  ) {}

  ngOnInit(): void {
    this.getUrlHref();
    this.getDomain();
    this.getSubDmn();
  }

  getUrlHref() {
    let urlCurr = this.serviceBase.getCurrentUrl();
    let urlBase = this.serviceBase.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlCurr + urlBase;
  }

  getDomain() {
    let dmMajor = this.serviceBase.getDomainMajor();
    let dmPtcl = this.serviceBase.getDomainPtcl();
    this.domainMajor = dmMajor;
    this.domainPtcl = dmPtcl;
  }

  getSubDmn() {
    let ptcl = 'https', port = '', domain = 'adisonjimenez';
    let dmnIntg = this.serviceBase.getDomainSub(ptcl, port, 'intg', domain);
    let dmnOdoo = this.serviceBase.getDomainSub(ptcl, port, 'odoo', domain);
    let dmnPex = this.serviceBase.getDomainSub(ptcl, port, 'pex', domain);
    let dmnPiis = this.serviceBase.getDomainSub(ptcl, port, 'piis', domain);
    let dmnPsx = this.serviceBase.getDomainSub(ptcl, port, 'psx', domain);

    this.domainIntg = dmnIntg;
    this.domainOdoo = dmnOdoo;
    this.domainPex = dmnPex;
    this.domainPiis = dmnPiis;
    this.domainPsx = dmnPsx;
  }
}
