import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WoShortenComponent } from './work/wo-shorten/wo-shorten.component';
import { WoShAdflyComponent } from './work/wo-shorten/wo-sh-adfly/wo-sh-adfly.component';
import { WoShCutyComponent } from './work/wo-shorten/wo-sh-cuty/wo-sh-cuty.component';
import { WoShExeComponent } from './work/wo-shorten/wo-sh-exe/wo-sh-exe.component';
import { WoShOuoComponent } from './work/wo-shorten/wo-sh-ouo/wo-sh-ouo.component';
import { WoShLandingComponent } from './work/wo-shorten/wo-sh-landing/wo-sh-landing.component';
import { GeExFooterComponent } from './geeks/ge-external/ge-ex-footer/ge-ex-footer.component';
import { GeExNavbarComponent } from './geeks/ge-external/ge-ex-navbar/ge-ex-navbar.component';
import { GeExProfileComponent } from './geeks/ge-external/ge-ex-profile/ge-ex-profile.component';
import { GeInNavbarComponent } from './geeks/ge-internal/ge-in-navbar/ge-in-navbar.component';
import { GeInFooterComponent } from './geeks/ge-internal/ge-in-footer/ge-in-footer.component';

@NgModule({
  declarations: [
    AppComponent,

    GeExFooterComponent,
    GeExNavbarComponent,
    GeExProfileComponent,

    GeInNavbarComponent,
    GeInFooterComponent,

    WoShortenComponent,
    WoShAdflyComponent,
    WoShCutyComponent,
    WoShExeComponent,
    WoShOuoComponent,
    WoShLandingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
