<main>
  <!-- section -->
  <section class="position-relative bnMdLanding">
    <div class="fixedElement">
      <img src="{{ urlFront }}/assets/apps/images/shorten/landing/closeup-developer.jpg"
      alt="Background Image" class="img-fluid bnMdBackImage" />
      <div class="container my-lg-8 position-relative bnMdContainer nMdBackContent">
        <div class="row align-items-center">
          <div class="col-12 mb-8"></div>
          <div class="col-lg-10 offset-lg-2 mb-6 mb-lg-0">
            <div class="text-center">
              <h5 class="display-5 fw-bold mb-3 text-white">
                <span>Publicaciones</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
