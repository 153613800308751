import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/funcs/base.service';

@Component({
  selector: 'app-wo-sh-exe',
  templateUrl: './wo-sh-exe.component.html',
  styleUrls: ['./wo-sh-exe.component.css']
})
export class WoShExeComponent implements OnInit {
  appTitle: string = Environment.title;
  domainMajor: string = '';
  domainPtcl: string = '';
  urlFront: string = '';

  domainIntg: string = '';
  domainOdoo: string = '';
  domainPex: string = '';
  domainPiis: string = '';
  domainPsx: string = '';

  dataShorten: any[] = [];

  taskCount: number = 0;
  stgTaskName: string = 'shExeTask';
  stgTaskData: number = 0;

  stgShortName: string = 'shExeShorten';
  stgShortData: any;

  constructor (
    private router: Router,
    private sanitizer: DomSanitizer,
    private serviceBase: BaseService,
  ) {}

  ngOnInit(): void {
    this.getUrlHref();
    this.getDomain();
    this.getSubDmn();
    this.dataJson();
    this.setTaskCount();
    this.storageVerify();
  }

  getUrlHref() {
    let urlCurr = this.serviceBase.getCurrentUrl();
    let urlBase = this.serviceBase.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlCurr + urlBase;
  }

  getDomain() {
    let dmMajor = this.serviceBase.getDomainMajor();
    let dmPtcl = this.serviceBase.getDomainPtcl();
    this.domainMajor = dmMajor;
    this.domainPtcl = dmPtcl;
  }

  getSubDmn() {
    let ptcl = 'https', port = '', domain = 'adisonjimenez';
    let dmnIntg = this.serviceBase.getDomainSub(ptcl, port, 'intg', domain);
    let dmnOdoo = this.serviceBase.getDomainSub(ptcl, port, 'odoo', domain);
    let dmnPex = this.serviceBase.getDomainSub(ptcl, port, 'pex', domain);
    let dmnPiis = this.serviceBase.getDomainSub(ptcl, port, 'piis', domain);
    let dmnPsx = this.serviceBase.getDomainSub(ptcl, port, 'psx', domain);

    this.domainIntg = dmnIntg;
    this.domainOdoo = dmnOdoo;
    this.domainPex = dmnPex;
    this.domainPiis = dmnPiis;
    this.domainPsx = dmnPsx;
  }

  storageVerify() {
    let vDataShortStg = this.storageGetItem(this.stgShortName);
    if (!vDataShortStg) { this.storageSetItem( this.stgShortName, [] ); }
    if (vDataShortStg && JSON.parse(vDataShortStg).length > 0) {
      this.storageRemoveItem(this.stgShortName);
    }
  }

  storageGetItem(stName: any) {
    return localStorage.getItem(
      stName
    );
  }

  storageSetItem(stName: any, stData: any) {
    localStorage.setItem(
      stName, JSON.stringify(
        stData
      )
    );
  }

  storageRemoveItem(stName: any) {
    localStorage.removeItem(
      stName
    );
  }

  storageUpdate(stName: any, stData: any) {
    let stgData = this.storageGetItem(stName);
    if (stgData) { localStorage.setItem(stName, stData); }
  }

  // Método para marcar una URL como segura
  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async dataJson() {
    let urlData = `${this.urlFront}/assets/shorten/json/exe.json`;
    const dataFetch = await this.fetchData(urlData);
    if (dataFetch && dataFetch.length > 0) {
      this.dataShorten = dataFetch;
      this.dataShorten.sort(
        (a, b) => parseInt(b.id) - parseInt(a.id)
      );
    }
  }

  async fetchData(url: any) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching the JSON data:', error);
      return null;
    }
  }

  actionTask(item: any) {
    // Incrementar el contador de clics
    this.taskCount++;
    this.storageUpdate(this.stgTaskName, this.taskCount);
    console.log(`Total Tareas ${this.taskCount}`);

    // Cambia la visibilidad de los elementos de texto
    const taskDanger = document.getElementById(`taskDanger_${item.id}`);
    const taskSuccess = document.getElementById(`taskSuccess_${item.id}`);
    const linkAction = document.getElementById(`linkAction_${item.id}`);

    if (taskDanger && taskSuccess) {
      taskDanger.classList.add('d-none');
      taskSuccess.classList.remove('d-none');
    }

    if (linkAction) {
      linkAction.classList.remove('btn-info');
      linkAction.classList.add('btn-success');
    }
  }

  setTaskCount() {
    let vDataTaskStg = this.storageGetItem(this.stgTaskName);
    if (!vDataTaskStg) { this.storageSetItem(this.stgTaskName, 0); }
    if (vDataTaskStg !== null) {
      let valTaskCount = parseInt(vDataTaskStg, 10);
      if (valTaskCount) {
        if (valTaskCount >= 1000) {
          this.storageRemoveItem(this.stgTaskName);
          this.storageSetItem(this.stgTaskName, 0);
        }
        if (valTaskCount < 1000) {
          this.taskCount = valTaskCount;
        }
      }
    }
  }
}
