<main>
  <!-- section -->
  <section class="position-relative bnMdLanding">
    <div class="fixedElement">
      <img src="{{ urlFront }}/assets/apps/images/shorten/landing/closeup-developer.jpg"
      alt="Background Image" class="img-fluid bnMdBackImage" />
      <div class="container my-lg-8 position-relative bnMdContainer nMdBackContent">
        <div class="row align-items-center">
          <div class="col-12 mb-8"></div>
          <div class="col-lg-10 offset-lg-2 mb-6 mb-lg-0">
            <div class="text-center">
              <h5 class="display-5 fw-bold mb-3 text-white">
                <span>Publicaciones</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<main class="docs_wrapper mt-6">
  <section class="container-fluid">
    <div class="row">
      <!-- Contenido Tarjeta -->
      <div class="col-12">
        <div class="card rounded-0 smooth-shadow-md bg-white">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-0 mt-0">
              <div class="d-flex align-items-center">
                <div class="ms-2 lh-1">
                  <h5 class="mb-1">
                    <span>Tareas Completadas</span>
                  </h5>
                  <span class="text-info">
                    {{ taskCount}} Tareas
                  </span>
                </div>
              </div>
              <div>
                <div class="ms-2 lh-1">
                  <h5 class="mb-1">
                    <span>Meta Diaria</span>
                  </h5>
                  <span class="text-info mb-1">
                    1000 Tareas
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Separacion de Espacio -->
      <hr class="mt-4 mb-4">
      <!-- Contenido Tarjeta -->
      <div class="col-6 col-lg-3" *ngFor="let item of dataShorten">
        <div class="card card-hover rounded-0 smooth-shadow-md mb-5 bg-white">
          <div class="card-header">
            <div class="card-title">
              <h4 class="form-label text-center">
                <span>{{ item.name }}</span>
              </h4>
            </div>
          </div>
          <div class="card-body">
            <h5 class="text-dark text-center" id="taskDanger_{{ item.id }}">
              <i class="fe fe-check icon-xxs icon-shape bg-danger text-white rounded-circle me-2"></i>
              <span>Tarea Incompleta</span>
            </h5>
            <h5 class="text-dark text-center d-none" id="taskSuccess_{{ item.id }}">
              <i class="fe fe-check icon-xxs icon-shape bg-success text-white rounded-circle me-2"></i>
              <span>Tarea Terminada</span>
            </h5>
          </div>
          <div class="card-footer">
            <a [href]="item.shorten" target="_blank" rel="noopener" id="linkAction_{{ item.id }}"
            class="btn btn-sm btn-info w-100 rounded-0" (click)="actionTask(item)">
              <span>Abrir Tarea</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
