import { Component } from '@angular/core';

@Component({
  selector: 'app-wo-shorten',
  templateUrl: './wo-shorten.component.html',
  styleUrls: ['./wo-shorten.component.css']
})
export class WoShortenComponent {

}
