import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WoShortenComponent } from './work/wo-shorten/wo-shorten.component';
import { WoShAdflyComponent } from './work/wo-shorten/wo-sh-adfly/wo-sh-adfly.component';
import { WoShCutyComponent } from './work/wo-shorten/wo-sh-cuty/wo-sh-cuty.component';
import { WoShExeComponent } from './work/wo-shorten/wo-sh-exe/wo-sh-exe.component';
import { WoShLandingComponent } from './work/wo-shorten/wo-sh-landing/wo-sh-landing.component';
import { WoShOuoComponent } from './work/wo-shorten/wo-sh-ouo/wo-sh-ouo.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'work/shorten/landing' },
  {
    path: 'work/shorten', component: WoShortenComponent,
    children: [
      { path: 'adfly', component: WoShAdflyComponent},
      { path: 'cuty', component: WoShCutyComponent},
      { path: 'exe', component: WoShExeComponent},
      { path: 'landing', component: WoShLandingComponent},
      { path: 'ouo', component: WoShOuoComponent},
    ],
  },
  { path: '**', redirectTo: 'work/shorten/landing' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
